import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect : '/index'
  },
  {
    path: "/index",
    name:'index',
    component:()=>import('../views/Index')
  },
  {
    path: "/success",
    name:'success',
    component:()=>import('../views/Success')
  },
  {
    path: "/form2",
    name:'FormPay',
    component:()=>import('../views/FormPay')
  },
  {
    path: "/form2Success",
    name:'FormPaySuccess',
    component:()=>import('../views/FormPaySuccess')
  },
  {
    path: "/sx",
    name:'FormSx',
    component:()=>import('../views/FormSx')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
