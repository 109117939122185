import Vue from 'vue'
import Vuex from 'vuex'
import cache from "@/utils/cache";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    appId1: '', // 公众号id（主）
    openId1: '', // 用户openId（主）
    appId2: '', // 公众号id（支付）
    openId2: '', // 用户openId（支付）
  },
  getters: {
    appId1(state){
      return state.appId1 || cache.local.get('appId1');
    },
    appId2(state){
      return state.appId2 || cache.local.get('appId2');
    },
    openId1(state){
      return state.openId1 || cache.local.get('openId1');
    },
    openId2(state){
      return state.openId2 || cache.local.get('openId2');
    }
  },
  mutations: {
    setAppId1(state, appId) {
      state.appId1 = appId;
      cache.local.set('appId1', appId);
    },
    setOpenId1(state, openId) {
      state.openId1 = openId;
      cache.local.set('openId1', openId);
    },
    setAppId2(state, appId) {
      state.appId2 = appId;
      cache.local.set('appId2', appId);
    },
    setOpenId2(state, openId) {
      state.openId2 = openId;
      cache.local.set('openId2', openId);
    },
  },
  actions: {
  },
  modules: {
  }
})
