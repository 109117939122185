<template>
  <div class="app">
<!--    <div class="backGroundImg10086">-->
<!--      <span style="margin-top: 20px;display: block">此网页由 wap.10086.cn 提供</span>-->
<!--    </div>-->
    <div class="ldy-loading">
      <div class="ldy-loading-text">加载中...</div>
    </div>
    <router-view />
  </div>
</template>

<script>
export default {

}
</script>

<style>
.ldy-loading{
  position: fixed;
  z-index: -10;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ldy-loading-text{
  font-size: 14px;
  color: #a7a7a7;
}
.backGroundImg10086{
  width:100vw;
  height: 100vh;
  position:fixed;
  z-index:-1;
  background-color: #EFEFEF;
  color: #c4c2c2;
  font-size: 12px;
  text-align: center;
  top: 0;
}
.app{
  padding: 0;
  margin: 0;
  font-size: 16px;
  background-color: #FFFFFF;
  /*height: 100vh;*/
}
input{
  border:none;
  outline: none;
}
button{
  border:none;
  background-color: white;
}
ul {
  margin: 0;
  padding: 0;
}

li {
  margin: 0;
  padding: 0;
  list-style: none; /*去掉li左侧的点*/
}

</style>
